@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    body{
        @apply scroll-smooth;
    }
    html::-webkit-scrollbar,
    body::-webkit-scrollbar {
        display: none;
    }
    
    html,
    body {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}